<h1 class="page-title">
  Agreement
  <button type="button" class="btn btn-transparent btn-xs" (click)="back()">
    <i class="fa fa-reply"></i>
    Back
  </button>
</h1>

<div class="col-lg-8 agreement-details">
  <section class="widget" *ngIf="entity">
    <header>
      <h4>
        {{ entity.AgreementNumber }}
        <i
          *ngIf="isPotentiallyHolded && entity.ServiceType !== 'AWS Hosting'"
          class="fa fa-warning"
        ></i>
        <button
          *ngIf="authService.isAdminOrEditor()"
          type="button"
          class="btn btn-transparent btn-xs edit-button"
          data-toggle="modal"
          data-target="#agreement-form-modal"
          (click)="model = entity"
        >
          <i class="fa fa-pencil"></i>
          Edit
        </button>
      </h4>
    </header>
    <div class="body">
      <div class="mt">
        <table class="table table-striped table-hover">
          <tbody>
            <tr>
              <td style="width: 25%">Client Code</td>
              <td>
                <a [routerLink]="['/app/client-accounts/view', entity.ClientCode]">
                  {{ entity.ClientCode }}
                </a>
              </td>
            </tr>

            <tr>
              <td>Client Name</td>
              <td>{{ clientName }}</td>
            </tr>

            <tr>
              <td>Product Number</td>
              <td>
                <a [routerLink]="['/app/products/view', entity.JobNumber]">
                  {{ entity.JobNumber }}
                </a>
              </td>
            </tr>

            <tr>
              <td>Agreement Number</td>
              <td>{{ entity.AgreementNumber }}</td>
            </tr>
            <tr>
              <td>Title</td>
              <td>{{ entity.Title }}</td>
            </tr>
            <tr>
              <td>Product Owner</td>
              <td>{{ entity.ProductOwner }}</td>
            </tr>
            <tr>
              <td>Jira Title</td>
              <td>
                <a href="{{ getJiraLink() }}" target="_blank"
                  >{{ entity.JiraTitle }}</a
                >
              </td>
            </tr>
            <tr>
              <td>Service Type</td>
              <td>{{ entity.ServiceType }}</td>
            </tr>
            <tr>
              <td>Start Date</td>
              <td>{{ transformDate(entity.StartDate) }}</td>
            </tr>
            <tr>
              <td>End Date</td>
              <td>{{ transformDate(entity.EndDate) }}</td>
            </tr>

            <tr>
              <td>Next Invoice</td>
              <td>{{ transformDate(entity.NextInvoiceDate||'') }}</td>
            </tr>
            <tr>
              <td>Billing Cycle</td>
              <td>{{ entity.BillingCycle }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{{ entity.Status }}</td>
            </tr>
            <tr>
              <td>Total Contract Cost</td>
              <td>${{ entity.InvoiceAmount | number }}</td>
            </tr>
            <tr>
              <td>Infrastructure Amount</td>
              <td>${{ (entity.HostingAmount | number) || 0 }}</td>
            </tr>
            <tr>
              <td>Service Amount</td>
              <td>${{ (entity.ServiceAmount | number) || 0 }}</td>
            </tr>
            <tr>
              <td>Support & Maintenance Amount</td>
              <td>${{ (entity.SupportMaintenanceAmount | number) || 0}}</td>
            </tr>
            <tr>
              <td>Has SLA</td>
              <td>{{ entity.HasSLA ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <td>Expiration period</td>
              <td>{{ entity.ExtendedLifetime ? '12 months' : '3 months' }}</td>
            </tr>
            <tr>
              <td>Support Hours</td>
              <td>{{ entity.SupportHours || 0 }}</td>
            </tr>
            <tr>
              <td>Jira Licenses</td>
              <td>{{ entity.JiraLicence || 0 }}</td>
            </tr>
            <tr>
              <td>Commissionable</td>
              <td>{{ entity.Commissionable ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <td>Cost per story point</td>
              <td title="${{ projectValue|number }} / {{ completedSp }}SP">
                <span *ngIf="completedSp">
                  ${{ (projectValue / completedSp)|number:'.0-2' }}
                </span>
                <span *ngIf="!completedSp"> - </span>
              </td>
            </tr>
            <tr>
              <td>Budget Note</td>
              <td>{{ entity.BudgetNote || '' }}</td>
            </tr>
            <tr>
              <td>Client Xero ID</td>
              <td>
                <a *ngIf="client?.XeroId"
                  target="_blank"
                  href="https://go.xero.com/Contacts/View.aspx?contactID={{ client?.XeroId }}"
                  title="{{ client?.XeroId }}"
                >
                  {{ client?.XeroId }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>
<div class="col-lg-4">
  <activity-stream
    [id]="'agreement' + (+(entity.AgreementNumber||0))"
    [createMessage]="'created agreement'"
  >
  </activity-stream>
</div>

<div class="col-xs-12">
  <projects
    [projectOwner]="entity.AgreementNumber || ''"
    ownerType="agreement"
    (hoursSaved)="afterSave()"
    (hoursRemoved)="afterSave()"
    [purchasedHours]="entity.PurchasedHours || []"
    [commisionable]="entity.Commissionable || false"
  >
  </projects>
</div>

<div class="col-lg-12">
  <section class="widget">
    <div class="body">
      <div class="mt">
        <table class="table table-striped table-hover">
          <tbody>
            <tr>
              <td>
                Addons
                <button
                  *ngIf="authService.isAdmin()"
                  type="button"
                  class="btn btn-transparent btn-xs add-addon-button"
                  title="Add Addon"
                  data-toggle="modal"
                  data-target="#addon-form-modal"
                >
                  <i class="glyphicon glyphicon-plus-sign"></i>
                </button>
              </td>
              <td>
                <table
                  *ngIf="entity?.Addons?.length"
                  class="table table-bordered table-lg table-hover"
                >
                  <thead>
                    <tr>
                      <th>Addon Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Billing Cycle</th>
                      <th>Status</th>
                      <th>Invoice Amount</th>
                      <th>Invoice Separately</th>
                      <th>Next Invoice</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let a of entity?.Addons; let i = index">
                      <td>{{ a.AddonName }}</td>
                      <td>{{ a.StartDate | date: 'dd/MM/y' }}</td>
                      <td>{{ a.EndDate | date: 'dd/MM/y' }}</td>
                      <td>{{ a.BillingCycle }}</td>
                      <td>{{ a.Status }}</td>
                      <td>${{ a.InvoiceAmount | number:'1.2' }}</td>
                      <td>{{ a.InvoiceSeparately ? 'Yes' : 'No' }}</td>
                      <td>{{ a.NextInvoiceDate | date:'dd/MM/y' }}</td>
                      <td>
                        <button
                          [disabled]="isAddonRemoving"
                          type="button"
                          class="btn btn-transparent btn-xs"
                          data-toggle="modal"
                          data-target="#addon-remove-modal"
                          (click)="addonIndex = i"
                        >
                          <i class="glyphicon glyphicon-remove-circle"></i>
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td>Documents</td>
              <td>
                <div *ngFor="let d of entity?.Documents">
                  {{ d.DocumentName }}
                  <button
                    type="button"
                    class="btn btn-transparent btn-xs"
                    title="View"
                    (click)="viewDocument(d.DocumentId)"
                  >
                    <i class="fa fa-eye"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td class="preserve-spaces">
                Unchargeable Hours

                <button
                  *ngIf="authService.isAdminOrEditor()"
                  type="button"
                  class="btn btn-transparent btn-xs add-unchargeable-hours-button"
                  title="Add Unchargeable Hours"
                  data-toggle="modal"
                  data-target="#unchargeable-form-modal"
                >
                  <i class="glyphicon glyphicon-plus-sign"></i>
                </button>
              </td>
              <td>
                <table
                  *ngIf="entity?.UnchargeableHours?.length"
                  class="table table-bordered table-lg table-hover"
                >
                  <thead>
                    <tr>
                      <th>Credited</th>
                      <th>Unchargeable Hours</th>
                      <th>Notes</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let a of entity?.UnchargeableHours; let i = index"
                    >
                      <td>{{ a.Month }} {{ a.Year }}</td>
                      <td>{{ a.Hours }}</td>
                      <td>{{ a.Notes }}</td>
                      <td>
                        <button
                          [disabled]="isHoursRemoving"
                          type="button"
                          class="btn btn-transparent btn-xs"
                          data-toggle="modal"
                          data-target="#hours-remove-modal"
                          (click)="removeHoursOptions = [i, 'uncharged', entity.UnchargeableHours]; removeHoursType = 'Unchargeable'"
                        >
                          <i class="glyphicon glyphicon-remove-circle"></i>
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td class="preserve-spaces">
                Unforeseen Hours

                <button
                  *ngIf="authService.isAdminOrEditor()"
                  type="button"
                  class="btn btn-transparent btn-xs add-unchargeable-hours-button"
                  title="Add Unforeseen Hours"
                  data-toggle="modal"
                  data-target="#unforeseen-form-modal"
                  >
                  <i class="glyphicon glyphicon-plus-sign"></i>
                </button>
              </td>
              <td>
                <table
                  *ngIf="entity?.UnforeseenHours?.length"
                  class="table table-bordered table-lg table-hover"
                  >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Hours</th>
                      <th>Notes</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let item of entity.UnforeseenHours; let i = index"
                      >
                      <td>{{ item.Month }} {{ item.Year }}</td>
                      <td>{{ item.Hours }}</td>
                      <td>{{ item.Notes }}</td>
                      <td>
                        <button
                          [disabled]="isHoursRemoving"
                          type="button"
                          class="btn btn-transparent btn-xs"
                          data-toggle="modal"
                          data-target="#hours-remove-modal"
                          (click)="removeHoursOptions = [i, 'unforeseen', entity.UnforeseenHours]; removeHoursType = 'Unforeseen'"
                          >
                          <i class="glyphicon glyphicon-remove-circle"></i>
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>


            <tr>
              <td class="preserve-spaces">
                Profitable Hours

                <button
                  *ngIf="authService.isAdminOrEditor()"
                  type="button"
                  class="btn btn-transparent btn-xs add-unchargeable-hours-button"
                  title="Add Profitable Hours"
                  data-toggle="modal"
                  data-target="#profitable-form-modal"
                >
                  <i class="glyphicon glyphicon-plus-sign"></i>
                </button>
              </td>
              <td>
                <table
                  *ngIf="entity?.ProfitableHours?.length"
                  class="table table-bordered table-lg table-hover"
                >
                  <thead>
                    <tr>
                      <th>Credited</th>
                      <th>Profitable Hours</th>
                      <th>Notes</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let a of entity?.ProfitableHours; let i = index"
                    >
                      <td>{{ a.Month }} {{ a.Year }}</td>
                      <td>{{ a.Hours }}</td>
                      <td>{{ a.Notes }}</td>
                      <td>
                        <button
                          [disabled]="isHoursRemoving"
                          type="button"
                          class="btn btn-transparent btn-xs"
                          data-toggle="modal"
                          data-target="#hours-remove-modal"
                          (click)="removeHoursOptions = [i, 'profited', entity.ProfitableHours]; removeHoursType = 'Profitable'"
                        >
                          <i class="glyphicon glyphicon-remove-circle"></i>
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td>Notes</td>
              <td>{{ entity.Notes }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>
<ul
  *ngIf="checkExpired() && entity?.Status === 'Expired'"
  class="messenger messenger-theme-air"
>
  <li
    class="messenger-message-slot messenger-shown messenger-first messenger-last"
  >
    <div class="col-lg-12">
      <div
        class="messenger-message message alert error message-error alert-error"
      >
        <div class="messenger-message-inner">
          <p>
            Caution: This agreement has ended on
            <b class="color-red">{{transformDate(entity.EndDate||'')}}</b>.
            Therefore, no time logs more recent than
            <b class="color-red">{{transformDate(entity.EndDate||'')}}</b> are
            shown. If the agreement has been renewed, please update the End Date
            to see the most recent time reporting data.
          </p>
        </div>
      </div>
    </div>
  </li>
</ul>
<div class="col-xs-12">
  <time-management-table [entity]="entity"></time-management-table>
</div>
<div class="col-xs-12">
  <resource-requests
    [linkedProject]="entity.AgreementNumber"
    projectType="agreement"
  ></resource-requests>
</div>

<modal-dialog
  [modalId]="'agreement-form-modal'"
  heading="Edit {{ entity.JobNumber}}"
  [broadcaster]="triggerBroadcaster"
>
  <agreement-form
    [broadcaster]="triggerBroadcaster"
    [model]="model!"
    (formSaved)="afterSave(true)"
  ></agreement-form>
</modal-dialog>

<modal-dialog
  [modalId]="'unchargeable-form-modal'"
  heading="Add Unchargeable Hours"
  [broadcaster]="triggerBroadcaster"
>
  <hours-form
    [agreement-number]="entity.AgreementNumber"
    type="uncharged"
    (formSaved)="afterSave()"
  ></hours-form>
</modal-dialog>

<modal-dialog
  [modalId]="'unforeseen-form-modal'"
  heading="Add Unforeseen Hours"
  [broadcaster]="triggerBroadcaster"
>
  <hours-form
    [agreement-number]="entity.AgreementNumber"
    type="unforeseen"
    (formSaved)="afterSave()"
  ></hours-form>
</modal-dialog>

<modal-dialog
  [modalId]="'profitable-form-modal'"
  heading="Add Profitable Hours"
  [broadcaster]="triggerBroadcaster"
>
  <hours-form
    [agreement-number]="entity.AgreementNumber"
    type="profited"
    (formSaved)="afterSave()"
  ></hours-form>
</modal-dialog>

<modal-dialog
  [modalId]="'hours-remove-modal'"
  heading="Confirmation"
  [withControls]="true"
  [broadcaster]="triggerBroadcaster"
  confirm-text="Delete"
  (acceptModal)="removeHours(removeHoursOptions)"
>
  Please confirm you'd like to delete this {{ removeHoursType }} Hours item.
  This action cannot be undone.
</modal-dialog>

<modal-dialog
  [modalId]="'addon-form-modal'"
  heading="Add Addon"
  [broadcaster]="triggerBroadcaster"
>
  <addon-form
    [agreement-number]="entity.AgreementNumber"
    (formSaved)="afterSave()"
  ></addon-form>
</modal-dialog>

<lcm-reservation [agreement-id]="entity.AgreementNumber"></lcm-reservation>
<modal-dialog
  [modalId]="'addon-remove-modal'"
  heading="Confirmation"
  [withControls]="true"
  [broadcaster]="triggerBroadcaster"
  confirm-text="Delete"
  (acceptModal)="removeAddon(addonIndex)"
>
  Please confirm you'd like to delete this addon. This action cannot be undone.
</modal-dialog>
