import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { JiraService } from '../../service/jira.service';
import { Agreement } from '../../prototype/agreement.prototype';
import { Opportunity } from '../../prototype/opportunity.prototype';
import { BroadcastService } from '../../service';

import * as moment from 'moment';
import { TDatesList } from '.';

type TimeOwner = Opportunity | Agreement;

function sortDates(left: string, right: string) {
  const l = moment(left.split('-').slice(-1)[0]);
  const r = moment(right.split('-').slice(-1)[0]);
  if (l.isBefore(r)) {
    return -1;
  }
  if (r.isBefore(l)) {
    return 1;
  }
  return 0;
}
@Component({
  templateUrl: 'time-management-table.component.html',
  selector: 'time-management-table',
})
export class TimeManagementTable implements OnInit {
  @Input() entity!: TimeOwner;

  protected timeTable: Object = {
    time: {},
    lastUpdated: null,
  };
  protected isPullingHours: boolean = false;
  protected isExportHours: boolean = false;
  protected pullHoursFailed: boolean = false;
  protected datesList: TDatesList = [];

  protected isAgreementTable: boolean = false;
  protected isOpportunityTable: boolean = false;

  timeManagementRows: any[] = [];

  constructor(
    protected jiraService: JiraService,
    private broadcast: BroadcastService
  ) {}

  ngOnInit(): void {
    this.isAgreementTable = 'AgreementNumber' in this.entity;
    this.isOpportunityTable = !('AgreementNumber' in this.entity);
    this.pullHours();
  }

  ejectStartDate(entity: TimeOwner): string {
    if (this.isOpportunityTable) {
      let date = moment();
      date.subtract(96, 'months');

      let allHours: any[] = []
        .concat(entity.PurchasedHours || [], entity.UnchargeableHours || [])
        .map((item: any) => moment(`${item.Year} ${item.Month}`, 'YYYY MMMM'))
        .concat(
          ((entity as any).Milestones || []).map((m: any) =>
            moment(m.CreatedAt)
          )
        );
      for (let hoursDate of allHours) {
        if (date.isAfter(hoursDate)) {
          date = hoursDate;
        }
      }
      return String(
        `${date.year()}-${('0' + (date.month() + 1)).slice(-2)}-01`
      );
    } else {
      return entity.StartDate;
    }
  }

  ejectEndDate(entity: TimeOwner): string | null {
    if (this.isOpportunityTable) {
      return null;
    } else {
      return entity.EndDate;
    }
  }

  pullHours(force: boolean = false): void {
    const title = this.entity.JiraTitle!;
    const endDate = moment().format('YYYY-MM-DD');
    const dateRange = [this.ejectStartDate(this.entity), endDate];

    const milestoneHours = ((this.entity as any).Milestones || []).map((ms) => {
      const date = moment(ms.CreatedAt);
      let hourlyRate: number, value: number;
      if (ms.HourlyRate) {
        hourlyRate = ms.HourlyRate;
        value = ms.Value;
      } else {
        hourlyRate = 175;
        value = ms.Currency === 'AUD' ? ms.Value : ms.Value / ms.Rate;
      }

      return {
        Month: date.format('MMMM'),
        Year: date.format('YYYY'),
        Hours: ~~(value / hourlyRate),
      };
    });
    const hours = {
      purchased: (this.entity.PurchasedHours || []).concat(milestoneHours),
      uncharged: this.entity.UnchargeableHours || [],
      unforeseen: this.entity.UnforeseenHours || [],
      uncharged_initial: this.entity.UnchargeableHours || [],
      profited: this.entity.ProfitableHours || [],
      profited_initial: this.entity.ProfitableHours || [],
      support: this.entity.SupportHours || 0,
      short_date: this.isOpportunityTable,
      extended: this.entity.ExtendedLifetime || false,
    };
    this.isPullingHours = true;
    this.isExportHours = true;

    this.jiraService.getTimeTable(title, dateRange, force, hours).subscribe(
      (data) => {
        data.lastUpdated = moment(data.lastUpdated).utc().local().calendar();

        this.isExportHours = this.isPullingHours = this.pullHoursFailed = false;

        this.datesList = Object.keys(data.time).sort(sortDates);
        const labels = {
          spent: `Hours spent (${data['lastUpdated'] || 'Loading...'})`,
          unused: 'Unused hours carried over',
          overused: 'Overused hours carried over',
          purchased: 'Support hours purchased',
          uncharged: 'Unchargeable time',
          unforeseen: 'Unforeseen time',
          profited: 'Profitable time',
          remaining: 'Hours remaining',
        };
        this.timeTable = data;

        const firstMeaningfulRecord: { [key: string]: string } = {};

        this.timeManagementRows = [
          'spent',
          'unused',
          'overused',
          'purchased',
          'uncharged',
          'profited',
          'unforeseen',
          'remaining',
        ].map((type) => {
          const row = {
            label: labels[type],
            tooltips: {},
          };
          for (let month of this.datesList) {
            let value = data['time'][month]?.[type] || '-';
            if (value !== '-' && !firstMeaningfulRecord[type]) {
              firstMeaningfulRecord[type] = month;
            }
            row[month] = value;
            if (type === 'uncharged') {
              let initial = data['time'][month]?.uncharged_initial;
              row.tooltips[month] =
                'Unchargeable tickets: ' +
                (value - initial).toFixed(2) +
                'hrs \n Unchargeable time: ' +
                initial.toFixed(2) +
                'hrs';
            } else if (type === 'unforeseen') {
              for (let record of this.entity.UnforeseenHours || []) {
                if (`${record.Month} ${record.Year}` !== month) continue;
                row.tooltips[month] = (row.tooltips[month] || "") + `${record.Hours}: ${record.Notes}\n\n`;
              }

            } else if (type === 'profited') {
              let initial = data['time'][month]?.profited_initial;
              row.tooltips[month] =
                'Profitable tickets: ' +
                (value - initial || 0).toFixed(2) +
                'hrs \n Profitable time: ' +
                (initial || 0).toFixed(2) +
                'hrs';
            }
          }
          return row;
        });

        if (this.datesList.length > 6) {
          const firstMeaningfulDate = Object.values(firstMeaningfulRecord).sort(
            sortDates
          )[0];
          let meaningfulIdx = Math.min(
            this.datesList.indexOf(firstMeaningfulDate),
            this.datesList.length - 6
          );

          this.datesList.splice(0, meaningfulIdx);
        }

        setTimeout(() => this.broadcast.next(['timetableloaded']));
      },
      () => {
        this.pullHoursFailed = true;
        this.isPullingHours = false;
        this.isExportHours = true;
      }
    );
  }
}
